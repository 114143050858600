import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/** 
 * @class
 * @author Sébastien Devroye <sebastien@infinity-mobile.io>
 */
export default class Feedback extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }
}
