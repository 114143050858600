import App from '@/App'
import Pepper from '@spices/pepper'
import { basil as $basil, install as Basil } from '@spices/basil'

import { createPinia, PiniaVuePlugin } from 'pinia'

import { install as basilI18n } from '@spices/basil-ic-i18n'
import phonenumber from '@/helpers/phonenumber'
import RedirectHandler from '@/helpers/redirect'

export default async ({ args, core, eventbus, i18n, logger, router, store, Vue }) => {
  logger.group('bootstrap.app')

  // Plugins
  $basil.global = true
  $basil.use(basilI18n)

  // Pinia
  const pinia = createPinia()
  Vue.use(PiniaVuePlugin)

  Vue.use(Pepper)
  Vue.use(Basil)

  // Accessors
  Object.defineProperty(Vue.prototype, '$bus', {
    get: () => eventbus
  })

  // Global filters
  Vue.filter('phonenumber', phonenumber)

  // Redirect handler
  let params = new URLSearchParams(window.location.search)
  let redirectHandler = new RedirectHandler({ 
    redirect: params.get('redirect'), 
    targetEmbedId: params.get('target_embed_id') 
  })

  new Vue({
    router,
    pinia: pinia,
    i18n: i18n.i18n,
    provide(){
      return {
        '$challenge': core.challenge,
        '$core': core,
        '$embed': core.embed,
        '$feedback': core.feedback,
        '$localStorage': core.localStorage,
        '$loyalty': core.loyalty,
        '$nft': core.nft,
        '$notification': core.notification,
        '$poap': core.poap,
        '$referral': core.referral,
        '$user': core.user,
        '$video': core.video,
        '$wallet': core.wallet,

        '$utils': core.utils,
        
        '$redirect': redirectHandler
      }
    },
    render: h => h(App)
  }).$mount('#app')

  store.bootstrap().args = args

  logger.groupEnd('bootstrap.app')
  return
}
