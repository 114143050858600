import { defineStore } from 'pinia'

import Config from '@/config'

export const useVideoStore = defineStore('video', {
  state: () => ({
    // Datas
    video: null,
    token: (window.sessionStorage && window.sessionStorage.getItem(`video_${Config.embedId}`)) || null,

    // Status
    loading: true,
  }),
})