import { CurryApi } from '@spices/curry'

import config from './config'

import Feedback from './feedback/model'

import { useFeedbackStore } from './store'

/**
 * Controller class for the entity Feedback
 * 
 * @class
 */
export default class FeedbackController {
  /**
   * @constructor
   */
  constructor({ logger, store, transports }) {
    // UTILS
    this._api = new CurryApi({ config, transports })
    this._logger = logger

    this._store = store
    this._store.register('feedback', useFeedbackStore)
  }

  /////////////////////////////////////////
  ///           INIT
  /**
   * Initialise 
   * 
   * @param {Object} options
   */
  async init({ }) {
    try {
      return
    } catch(e) {
      throw e
    }
  }

  /////////////////////////////////////////
  ///           GETTERS

  /**
   * @property {Boolean} loading
   * @readonly
   * @return {Boolean}
   */
  get loading() {
    return this._store.feedback().loading
  }

  /**
   * @property {Feedback} feedback
   * @readonly
   * @return {Feedback}
   */
  get feedback() {
    return this._store.feedback().feedback
  }

  /**
   * @property {Array<Feedback>} feedbacks
   * @readonly
   * @return {Array<Feedback>}
   */
  get feedbacks() {
    return this._store.feedback().feedbacks
  }

  /////////////////////////////////////////
  ///           METHODS  
  async getFreeSurveys() {
    this._store.feedback().loading = true
    this._store.feedback().feedbacks = null
    try {
      const { data } = await this._api.get({ type: 'collection' }) 
      this._store.feedback().feedbacks = (data?.data ?? []).map((f) => new Feedback(f))
      this._store.feedback().pagination = data?.meta?.pagination ?? null
      return this._store.feedback().feedbacks
    } catch(e) {
      throw e
    } finally {
      this._store.feedback().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.surveyId
   */
  async viewFreeSurvey({ surveyId }) {
    this._store.feedback().loading = true
    this._store.feedback().feedback = null
    try {
      const { data } = await this._api.get({ type: 'freeSurvey', payload: { feedbackId: surveyId } }) 
      this._store.feedback().feedback = new Feedback(data)
      return this._store.feedback().feedback
    } catch(e) {
      throw e
    } finally {
      this._store.feedback().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.surveyId
   * @param {Object} options.answers
   */
  async submitFreeSurvey({ surveyId, answers }) {
    this._store.feedback().loading = true
    try {
      const { data } = await this._api.post({ type: 'freeSurvey', payload: { feedbackId: surveyId, item: { answers } } }) 
      return data
    } catch(e) {
      throw e
    } finally {
      this._store.feedback().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.surveyId
   * @param {Object} options.user
   */
  
  async claimFreeSurvey({ surveyId, user }) {
    this._store.feedback().loading = true
    try {
      await this._api.post({ type: 'claimSurvey', payload: { feedbackId: surveyId, item: user } }) 
    } catch(e) {
      throw e
    } finally {
      this._store.feedback().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.token
   */
  async view({ token }) {
    this._store.feedback().loading = true
    this._store.feedback().feedback = null
    try {
      const { data } = await this._api.get({ type: 'entity', payload: { token } }) 
      this._store.feedback().feedback = new Feedback(data)
      return this._store.feedback().feedback
    } catch(e) {
      throw e
    } finally {
      this._store.feedback().loading = false
    }
  }

  /**
   * @param {Object} options 
   * @param {String} options.feedbackId
   * @param {Object} options.answers
   */
  async send({ token, answers }) {
    this._store.feedback().loading = true
    try {
      const { data } = await this._api.post({ type: 'entity', payload: { token, item: { answers } }})
      return data
    } catch (e) {
      throw e
    } finally {
      this._store.feedback().loading = false
    }
  }
}
