export default {
  displayType: {
    source: 'displayType',
    type: String
  },
  maxValue: {
    source: 'maxValue',
    type: String,
    default: null,
  },
  name: {
    source: 'name',
    type: String
  },
  value: {
    source: 'value',
    type: String
  }
}
