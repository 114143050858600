export default {
  id: { source: 'id', type: String },
  description: { source: 'description', type: String },
  entries: { source: 'entries', type: Array },
  images: { source: 'images', type: Array },
  questions: { source: 'questions', type: Array },
  submissionCount: { source: 'submission_count', type: Number },
  submissionDate: { source: 'submission_date', type: String },
  title: { source: 'title', type: String }
}
