export default {
  fqn: 'ifm.feedback',

  endpoints: {
    entity: {
      get: '/feedback/$token',
      post: '/feedback/$token'
    },

    collection: {
      get: '/user/feedbacks',
    },

    freeSurvey: {
      get: '/user/feedback/$feedbackId',
      post: '/user/feedback/$feedbackId'
    },

    claimSurvey: {
      post: '/user/feedback/$feedbackId/claim'
    }
  },

  transport: 'cp'
}