export default {
  fqn: 'ifm.stream',

  endpoints: {
    entity: {
      get: '/user/video/$token/view'
    },

    keepalive: {
      get: '/user/video/$token/keepalive'
    }
  },

  transport: 'stream'
}