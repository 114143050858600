import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    // Datas
    countries: [],
    user: null,
    vouchers: [],
    receipts: {
      data: [],
      pagination: {}
    },

    // Status
    loading: true,
  }),
})