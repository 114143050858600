import { basil } from '@spices/basil'

import { CurryModel } from '@spices/curry'
import manifest from './manifest'

/**
 * Loyalty class for a user
 * 
 * @class
 */
export default class User extends CurryModel {
  /**
   * @constructor
   */
  constructor(data = null) {
    super({ data, manifest })
  }

  /////////////////////////////////////////
  ///           GETTERS
  get hasWallet() {
    return this.isLogged && this.walletAddress != null
  }

  /**
   * @property {Boolean}
   * @readonly
   */
  get isAnonymous() {
    return this.anonymous
  }
  
  /**
   * @property {Boolean}
   * @readonly
   */
  get isGuest() {
    return !this.anonymous && this.guest
  }

  /**
   * @property {Boolean}
   * @readonly
   */
  get isLogged() {
    return !this.anonymous && !this.guest
  }

  /////////////////////////////////////////
  ///           METHODS
  /**
   * @param {Boolean} hasBalance
   * @readonly
   */
  hasBalance() {
    return !basil.isNil(this.balance)
  }
}
