export default () => {
  return [
    ///////////////////
    // Feedback
    {
      name: 'sayl-user-portal.surveys',
      path: '/surveys',
      component: () => import('./surveys/index.vue'),
      meta: {
        bodyClass: 'layout-survey view-surveys',
        scrollTop: true,
        header: true,
        footer: true
      }
    },
    {
      name: 'sayl-user-portal.survey',
      path: '/survey/:surveyId',
      component: () => import('./surveys/item.vue'),
      meta: {
        bodyClass: 'layout-survey view-survey',
        scrollTop: true,
        header: true,
        footer: true
      }
    },
    {
      name: 'sayl-user-portal.feedback',
      path: '/feedback/:token',
      component: () => import('./feedback/index.vue'),
      meta: {
        bodyClass: 'layout-feedback view-feedback',
        scrollTop: true,
        header: true,
        footer: true
      }
    }
  ]
}
