import DataLayer from '../data'
import Error from '../../helpers/error'
import { default as Config, isDevelopment } from '@/config'

export default async ({ args, eventbus, i18n, logger, states, store, transports }) => {
  logger.group('bootstrap.cp')

  let cp = transports.cp
  let stream = transports.stream

  store.bootstrap().status = states.APPLICATION_AUTH

  let embedId = Config.embedId

  if(isDevelopment() && !embedId) {
    embedId = '6464d501fb42297e5d04e654'
  }

  if(!embedId) {
    throw Error.EMBED_NOTFOUND
  }

  cp.defaults.headers.common['X-Embed-ID'] = embedId
  stream.defaults.headers.common['X-Embed-ID'] = embedId

  // Support for the no-cache
  if(args.nocache === '1') {
    cp.defaults.headers.common['Cache-Control'] = 'no-cache'
  }

  const dl = new DataLayer()

  eventbus.$on('logout', () => dl.logout())

  try {
    let config = await dl.bootstrapClientProject({ store, locale: i18n.locale })
    
    i18n.setConfig(config.i18n)
    i18n.update()

    store.bootstrap().setI18n(i18n)

    // Not sure of the utility of this
    // if(!basil.isNil(embedId)) {
    //   embedId = basil.get(config, 'embed.id')
    //   cp.defaults.headers.common['X-Embed-ID'] = embedId
    // }

    delete cp.defaults.headers.common['Cache-Control']

    return
  } catch(e) {
    throw e
  } finally {
    logger.groupEnd('bootstrap.cp')
  }
}
