import { isAuthenticated, isNotAuthenticated } from '@/helpers/router.js'

export default () => {
  let ret = [
    {
      name: 'sayl-user-portal.home',
      path: '/',
      component: () => import('./home'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-home layout-module-users user-auth',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-user-portal.loyalty',
      path: '/loyalty',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-user-portal.loyalty.vouchers',
      path: '/loyalty',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
        action: 'vouchers'
      }
    },

    {
      name: 'sayl-user-portal.loyalty.balance',
      path: '/loyalty',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
        action: 'balance'
      }
    },
    {
      name: 'sayl-user-portal.loyalty.cards',
      path: '/loyalty',
      component: () => import('./loyalty/index.vue'),
      meta: {
        bodyClass: 'view-user-loyalty layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
        action: 'cards'
      }
    },

    {
      name: 'sayl-user-portal.challenges',
      path: '/challenges',
      component: () => import('./challenges'),
      meta: {
        bodyClass: 'view-challenges layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    {
      name: 'sayl-user-portal.challenge',
      path: '/challenges/:id',
      component: () => import('./challenges/item'),
      meta: {
        bodyClass: 'view-challenge layout-module-users',
        scrollTop: true,
        header: true,
        footer: true
      }
    },

    //
    // Account
    //
    {
      name: 'sayl-user-portal.addresses',
      path: '/addresses',
      component: () => import('./account/addresses'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-addresses layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-user-portal.user',
      path: '/profile',
      component: () => import('./account/profile.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-profile layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-user-portal.wallet',
      path: '/wallet',
      component: () => import('./account/wallet.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-wallet layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      },
      children: [
        {
          name: 'sayl-user-portal.wallet.nft',
          path: '/wallet/nft/:contract/:id',
          component: () => import('./account/nft.vue'),
          beforeEnter: isAuthenticated,
        }
      ]
    },
    {
      name: 'sayl-user-portal.poaps',
      path: '/poaps',
      component: () => import('./account/poaps.vue'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-poaps layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      },
      children: [
        {
          name: 'sayl-user-portal.poap',
          path: '/poap/:id',
          component: () => import('./account/poap.vue'),
          beforeEnter: isAuthenticated,
        }
      ]
    },
    {
      name: 'sayl-user-portal.vouchers',
      path: '/vouchers',
      component: () => import('./account/vouchers'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-vouchers layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-user-portal.receipts',
      path: '/receipts',
      component: () => import('./account/receipts'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-receipts layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-user-portal.transfers',
      path: '/transfers',
      component: () => import('./account/transfers'),
      beforeEnter: isAuthenticated,
      meta: {
        bodyClass: 'view-user-wallet view-user-transfers layout-module-users',
        scrollTop: true,
        header: true,
        footer: true,
      },
      children: [
        {
          name: 'sayl-user-portal.transfers.nft',
          path: '/transfers/nft/:transfer/:contract/:id',
          component: () => import('./account/nft.vue'),
          beforeEnter: isAuthenticated,
          meta: {
            bodyClass: 'view-user-wallet view-user-transfers layout-module-users',
          },
        }
      ]
    },

    //
    //  Authentification
    //
    {
      name: 'sayl-user-portal.login',
      path: '/login',
      component: () => import('./auth/login'),
      beforeEnter: isNotAuthenticated,
      meta: {
        bodyClass: 'view-user-login layout-module-users user-auth',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
    {
      name: 'sayl-user-portal.signup',
      path: '/signup',
      component: () => import('./auth/signup'),
      beforeEnter: isNotAuthenticated,
      meta: {
        bodyClass: 'view-user-signup layout-module-users user-auth',
        scrollTop: true,
        header: true,
        footer: true,
      }
    },
  ]

  return ret
}
