export default {
  fqn: 'ifm.video',

  endpoints: {
    grantAccess: {
      post: '/user/video/$triggerId'
    }
  },

  transport: 'cp'
}