import { defineStore } from 'pinia'

export const useFeedbackStore = defineStore('feedback', {
  state: () => ({
    // Datas
    feedback: null,
    feedbacks: null,
    pagination: null,

    // Status
    loading: true,
  }),
})