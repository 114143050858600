import SaylStore from '@/bootstrap/stores'

export default () => {
  let ret = [
    ///////////////////
    // Videos
    {
      name: 'sayl-user-portal.stream.video',
      path: '/stream/video',
      component: () => import('./videos/index.vue'),
      beforeEnter: (to, from, next) => {
        if (SaylStore.user().user.isLogged && SaylStore.video().token) {
          next()
          return
        }
        next(
          SaylStore.user().user.isLogged ?
          { name: 'sayl-user-portal.home' } :
          { name: 'sayl-user-portal.login' }
        )
      },
      meta: {
        bodyClass: 'layout-stream view-stream-video',
        scrollTop: true,
        header: true,
        footer: true
      }
    }
  ]

  return ret
}
