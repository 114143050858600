import Bugsnag from '@bugsnag/js'
import Config from '@/config'
import cp from './transports/cp'
import stream from './transports/stream'

export default {
  async bootstrap({ locale }) {
    let boots = window.sayl_bootstrap || null
    
    if(boots) {
      cp.defaults.baseURL = Config.transports.cp.api
      cp.defaults.headers.common['X-CSRF-TOKEN'] = boots.csrf_token
      return boots
    }

    let url = [Config.transports.cp.api, 'bootstrap'].join('')
    let args = ['convert=1']
    if(locale) {
      args.push(`locale=${locale.iso}`)
    }
    args = args.join('&')
    url = [url, args].join('?')

    let timeout = setTimeout(() => Bugsnag.notify(new Error('It took more than 5 seconds to load the bootstrap.')), 5000)
    
    try {
      let { data } = await cp.get(url)
      
      timeout != null && clearTimeout(timeout)

      cp.defaults.baseURL = Config.transports.cp.api
      cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token

      stream.defaults.baseURL = Config.transports.stream.api

      return data
    } catch(e) {
      throw e
    } 
  },

  async keepAlive() {
    try {
      let { data } = await cp.get('keepalive')
      cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token
      
      return
    } catch(e) {
      if(e.message != 'Network Error') {
        Bugsnag.notify(e)
      }

      throw e
    }
  },

  async logout() {
    let url = [Config.transports.cp.oauth, 'forget'].join('')

    try {
      await oauth.post(url)

      sessionStorage.removeItem('email')
      document.location = '/'
      document.location.reload()
      return 
    } catch(e) {
      throw e
    }
  },

  async reset() {
    let url = ['bootstrap', '?convert=1&reset=1'].join('')

    try {
      let { data} = await cp.get(url)
      
      cp.defaults.baseURL = Config.transports.cp.api
      cp.defaults.headers.common['X-CSRF-TOKEN'] = data.csrf_token

      return data
    } catch(e) {
      throw e
    }
  }
}
