import Axios from 'axios'

const stream = Axios.create({
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
})

let cpt = 0

const response = (response) => {
  cpt = 0
  return response
}

const error = (error) => {
  const ret = {
    status: null
  }
  
  if(error && error.response && error.response.status) {
    ret.status = error.response.status
  }
  
  if(cpt < 2 && ([502, 504, 501].includes(ret.status))) {
    cpt++
    return cp.request(error.config)
  } else {
    cpt = 0
  }

  return Promise.reject(error)
}

stream.interceptors.response.use(response, error)

export default stream
