export default {
  created: {
    source: 'created',
    type: String
  },
  id: {
    source: 'id',
    type: String
  },
  lang: {
    source: 'lang',
    type: String
  },
  lines: {
    source: 'lines',
    type: Array
  },
  modified: {
    source: 'modified',
    type: String
  },
  picture: {
    source: 'picture',
    type: String
  },
  status: {
    source: 'status',
    type: Number
  }
}